@import '../../Style Guide/variables.scss';

.domain-card {
    padding: 15px 0px 15px 50px;
    border-radius: 10px;
    border: 2px solid #A8D6F6;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.domain-card:hover {
    border: 2px solid #2362B6;
    box-shadow: 0px 4px 21px 0px #00000029;

    .img-container .overlay {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
    }
}

.domain-card .img-container {
    width: 387px;
    height: 251px;
    margin-right: 60px;
    border-radius: 5px;
    position: relative;

    img {
        width: 100%;
    }

    .overlay {
        width: 100%;
        height: 100%;
        background-color: #14344BC7;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 5px;
        color: white;
        line-height: 21px;
        font-size: 13px;
        font-weight: 400;
        padding-left: 28px;
        padding-right: 28px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.index {
    background-color: #2362B6;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px;
    color: white;
    font-size: $h3-size;
    font-weight: 500;
    border-radius: 10px;
    width: 100px;
    height: 94px;
    margin-right: 28px;
}

.arrow {
    padding: 15px 65px;
    border-left: 1px solid #A8D6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 251px;
    cursor: pointer;
}

.domain-parent {
    padding-top: 150px;
    padding-bottom: 100px;
    background: linear-gradient(180deg, rgba(201, 159, 227, 0.26) 0%, rgba(180, 207, 243, 0.26) 10%, rgba(255, 255, 255, 0.26) 50%, rgba(255, 255, 255, 0.26) 100%);
}

.h_32_500 {
    font-size: 32px;
    font-weight: 500;
    line-height: 37.5px;
}

.rowBetweenCenter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rowStartCenter {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.rowCenter {
    display: flex;
    align-items: center;
}

.flex_1 {
    flex: 1
}

section.healthcareit {
    h2 {
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        margin-bottom: 8px;
        text-align: center;
    }

    p {
        line-height: 19px;
    }

    .col-md-4 {
        background-image: url(http://localhost:3000/static/media/one.f6afe59….webp);
        height: 353px;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        justify-content: end;
        align-items: end;
    }

    .card {
        padding: 32px 24px;
        box-shadow: 0px 4px 15px 0px #215DAD40;
        width: 355px;
        height: 227px;
        background-image: url('../../assets/images/services/card_bg.png');
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;

        h4 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 600;
            line-height: 28.13px;
        }

        p {
            line-height: 19px;
        }
    }

    .one {
        background-image: url('../../assets/images/services/one.webp');
    }

    .two {
        background-image: url('../../assets/images/services/two.webp');
    }

    .three {
        background-image: url('../../assets/images/services/three.webp');
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            font-size: 24px;
        }
    }
}

section.who {
    background-image: url('../../assets/images/services/Section.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 0px;

    h2 {
        font-size: 48px;
        font-weight: 600;
        text-align: center;
        color: white;
        margin-bottom: 50px;
        line-height: 56.25px;
    }

    .grid-item {
        background-color: white;
        padding: 13px 70px;
    }

    ul {
        list-style: none;
        display: grid;
        grid-template-columns: 300px 300px;
        padding: 0px;
        row-gap: 32px;
        column-gap: 216px;
        justify-content: center;

        li {
            color: white;
            padding-left: 30px;
            position: relative;
            font-weight: 500;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 18px;
                height: 18px;
                background-image: url('../../assets/images/services/arrow.webp');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        ul {
            grid-template-columns: 300px;
            row-gap: 32px;
            column-gap: 0px;
            justify-content: center;

            li {
                &::before {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        h2 {
            font-size: 24px;
            line-height: 30px;
        }

        .grid-item {
            padding: 13px 30px;
        }
    }
}

section.you-name {
    background-image: url('../../assets/images/services/you_name.webp');
    background-repeat: no-repeat;
    background-position: right;
    background-size: auto 510px;
    height: 510px;
    display: flex;
    align-items: center;

    h3 {
        font-size: 34px;
        margin-bottom: 24px;
        line-height: 40px;
    }

    ul {
        list-style: none;
        padding-left: 0px;

        li {
            padding-left: 30px;
            position: relative;
            line-height: 19px;
            margin-bottom: 16px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-100%);
                width: 18px;
                height: 18px;
                background-image: url('../../assets/images/services/arrow_blue.webp');
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        background-image: none;
        height: fit-content;

        h3 {
            font-size: 24px;
            text-align: center;
        }

        ul {
            li {
                text-align: justify;

                &::before {
                    width: 13px;
                    height: 13px;
                }
            }
        }
    }
}

section.hc-solutions {
    position: relative;
    padding: 60px 0px;

    h2 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 35px;
    }
}

section.benefits {
    padding: 60px 0px;
    background-color: #E6EEFB;

    h2 {
        text-align: center;
        font-weight: 600;
        margin-bottom: 50px;
    }

    .grid_container_2 {
        grid-template-columns: 430px 430px;
        justify-content: center;
        column-gap: 216px;
    }

    .grid-item {
        background-color: white;
    }

    @media screen and (max-width: 767.98px) {
        .grid_container_2 {
            grid-template-columns: minmax(200px, 1fr);
            justify-content: center;
            column-gap: 0px;
        }
    }
}

section.our-services {
    padding: 60px 0px;

    h2 {
        text-align: center;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .grid_container_2 {
        margin-top: 30px;
    }

    .grid-item {
        display: flex;
        align-items: center;
        border: none;
        border-radius: 0px;
        padding: 0px;
        box-shadow: none;
        color: #212529;
        font-size: 24px;
        font-weight: 600;
        justify-content: start;
        text-align: left;
        height: 138px;
        max-height: unset;
        line-height: 28px;
        border-bottom: 2px solid #266FF2;

        span {
            color: #808080;
            padding: 0px 8px;
            height: 100%;
            display: flex;
            align-items: center;
        }

        div {
            padding: 24px;
            height: 138px;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        h3 {
            font-size: 24px;
            margin-bottom: 0px;
        }

        p {
            font-weight: 400;
            line-height: 19px;
            margin-top: 0px;
            margin-bottom: 0px;
            opacity: 0;
            visibility: hidden;
            height: 0px;
            transition: opacity 1s linear, visibility 0.3s linear;
        }

        &:hover {
            span {
                background-color: #E6EEFB;
            }

            div {
                background-color: #14344B;
                color: white;
            }

            p {
                opacity: 1;
                visibility: visible;
                height: unset;
                margin-top: 8px;
            }
        }
    }

    @media screen and (max-width: 767.98px) {
        .grid-item {
            height: unset;
            min-height: 138px;

            div {
                height: unset;
                min-height: 138px;
            }
        }
    }
}

section.technology {
    text-align: center;

    .grid_container_3 {
        margin-bottom: 30px;
        margin-top: 50px;
    }

    h3 {
        margin-bottom: 8px;
    }

    p {
        line-height: 19px;
    }

    h2 {
        font-weight: 600;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::after {
            content: '';
            width: 26%;
            height: 2px;
            background-color: #2A63B7;
            margin-top: 8px;
            margin-bottom: 16px;
        }
    }
}

section.banner-text.how {
    h2 {
        margin-bottom: 50px;
        font-weight: 600;
        height: 26px;
    }

    .grid_container_3 {
        margin-bottom: 30px;
        column-gap: 99px;
        min-height: 82px;

    }

    .grid_container_2 {
        column-gap: 154px;
        min-height: 82px;
    }
}

section.banner-text.metrics {
    h2 {
        margin-bottom: 50px;
        font-weight: 600;
        line-height: 56px;
    }

    @media screen and (max-width: 767.98px) {
        h2 {
            line-height: 40px;
        }
    }

}


section.banner-text.exam {
    h2 {
        margin-bottom: 8px;
        font-weight: 600;
    }

    p {
        margin-bottom: 50px;
    }
}

section.benefits.education {
    text-align: center;

    h2 {
        margin-bottom: 9px;
        line-height: 56px;
    }

    .grid_container_3 {
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

section.virtual {
    h2 {
        font-weight: 600;
        text-align: center;
        margin-bottom: 9px;
        line-height: 56px;

    }

    .rss-h2 {
        margin-bottom: 50px;
    }

    p {
        text-align: center;
        margin-bottom: 50px;
    }

    .virtual-image {
        max-width: 490px;
    }

    .rss-image {
        max-width: 350px;
    }

    .rss.blue-arrows {
        li {
            margin-bottom: 40px;
        }
    }

    .align-desktop-center{
        align-items: center;
    }

    @media screen and (max-width: 767.98px) {
        .virtual-image {
            margin-bottom: 50px;
        }

        h2 {
            line-height: 32px;
            margin-bottom: 38px;
            text-align: left;
        }

        .rss.blue-arrows {
            li {
                margin-bottom: 20px;
            }
        }

        .align-desktop-center{
            align-items: unset;
        }
    }

}

section.sn-how {
    background: radial-gradient(circle 1000px at top right, #e8e0fc, transparent),
        radial-gradient(circle 500px at bottom left, #e8e0fc, transparent);

    h2 {
        font-weight: 600;
    }

    img {
        height: 554px;
        width: fit-content;
    }

    @media screen and (max-width: 767.98px) {
        img {
            padding: 50px 0px;
            margin: auto;
        }

    }
}

ul.blue-arrows {
    list-style: none;
    padding-left: 0px;

    li {
        padding-left: 30px;
        position: relative;
        line-height: 19px;
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 500;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-image: url('../../assets/images/services/arrow_blue.webp');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

.mobile {
    display: none !important;
}

@media screen and (max-width: 767.98px) {
    .domain-parent {
        background: none;
        padding: 21px 0px;
    }

    .domain-card {
        flex-direction: column;
        padding: 15px 0px;

        .rowBetweenCenter {
            flex-direction: column;
        }

        .rowBetweenCenter>div {
            flex-direction: column;
        }

        .index {
            width: 72.38px;
            height: 68px;
            font-size: 24px;
            margin-right: 0px;
            margin-bottom: 30px;
        }

        .h_32_500 {
            font-size: 24px;
        }

        .img-container {
            margin-right: 0px;
            width: 316px;
        }

        .arrow {
            border-left: 0;
            border-top: 1px solid #A8D6F6;
            height: 148px;
            width: 95%;
        }
    }

    .overlay {
        font-size: 13px;
        font-weight: 400;
        line-height: 21px;
    }

    ul.blue-arrows {
        li {
            line-height: 30px;
            font-size: 16px;

            &::before {
                width: 13px;
                height: 13px;
            }
        }
    }

    .mobile {
        display: block !important;
    }
}