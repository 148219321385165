@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

.hero-image {
	animation: hero_transition 2.5s ease-in-out infinite alternate-reverse both;
	padding: 60px 0 0px;
}

section.herosection {
	background-image: url('../assets/images/hero.webp');
	background-position-y: bottom;
	background-position-x: right;
	background-repeat: no-repeat;
	background-size: contain;
}

.so-far {
	color: #004AAD;
	text-align: center;
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	/* margin: 20px; */
	gap: 20px;
}

.p-title {
	margin-bottom: 0px;
	line-height: 24px;
}

.social-icon {
	height: 16px;
	width: auto;
}

.social-media div,
.responsive-social-media div {
	text-decoration: none;
	color: #000000;
	display: flex;
	align-items: center;
	gap: 13px;
	cursor: pointer;
	margin-bottom: 15px;
}

.responsive_img {
	display: none;
}

.social-media,
.responsive-social-media {
	display: flex;
	justify-content: space-between;
	align-items: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	color: #1B2E59;
	margin: 140px 0px 93px 0px;
	flex-wrap: wrap;
	line-height: 30px;
}

.responsive-social-media {
	display: none;
	margin: 40px 0px 50px 0px;
}

section.herosection {
	z-index: 1;
	position: relative;
	background-color: #ffffff;
	padding-bottom: 0px;
	/* margin-top: -30px; */
}

/* section.herosection::before {
	background-image: url('../assets/images/hero-banner.svg');
	content: "";
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	background-size: cover;
	background-repeat: no-repeat;
} */

section.herosection h5 {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

nav.navbar.navbar-expand-sm.bg-light.custom-nav-bar.navbar-light.fixed-top {
	background: #fff !important;
	box-shadow: 0 10px 15px 0 #1919191A;
}

.custom-nav-bar a.nav-link {
	color: #000248;
	font-weight: 500;
}

.nav-item {
	margin-left: 45px;
}

.btn-secondary a {
	text-decoration: none;
	color: #fff;
}

.hero-cta button {
	margin-right: 20px;
	border-radius: 5px;
}

button a {
	text-decoration: none;
}

.hero-cta {
	padding-top: 34px;
}

section.herosection h1 {
	font-weight: 900;
	padding-top: 69px;
	font-size: 60px;
	line-height: 69.6px;
	margin-bottom: 24px;
}

section.why-us h2 {
	margin-bottom: 20px;
}

section.services img {
	max-width: 400px;
}

.services-info h3 {
	color: #193961;
	font-size: 24px;
	font-weight: 500;
	line-height: 28.8px;
	text-align: center;
	margin-top: 20px;
}

.clients-container {
	text-align: center;
}

.clients-container h6 {
	margin: 20px;
}

.box-with-shadow {
	background: #ffffff;
	padding: 35px;
	text-align: center;
	border-radius: 5px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
}

section.contact form {
	text-align: left;
}

section.banner-text {
	background-image: url('../assets/images/services/Section.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	color: #fff;
	text-align: center;
	padding: 60px 0px;
}

section.banner-text h2 {
	margin-bottom: 20px;
}

section.banner-text h4 {
	font-size: 34px;
	font-weight: 600;
}

section.blog {
	text-align: center;
	/* padding: 60px 0px; */
}

section.blog h2 {
	margin-bottom: 20px;
}

section.blog h3 {
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 16px;
	line-height: 41px;
}

section.why-us ::marker {
	font-family: "Material Icons";
	content: "check_circle";
	color: #5e4690;
}

.why-us li {
	font-size: 18px;
	margin-bottom: 10px;
	color: #334155;
}

.btn .material-icons-outlined {
	font-size: 18px;
	vertical-align: middle;
	margin-left: 8px;
}

.why-us ul {
	padding-left: 0px;
	list-style-position: inside;
}

section.projectd-completed {
	background-image: linear-gradient(to top right, #3a72bf, #0d53ad);
	padding: 60px 0px;
}

section.projectd-completed p {
	color: #fff;
	text-align: center;
	margin-bottom: 0px;
}

p.count {
	font-weight: 700;
	font-size: 40px;
	margin-bottom: 0px;
	line-height: 81px;
}

section.services h2 {
	font-size: 48px;
	font-weight: 700;
	text-align: center;
	position: relative;
	padding-bottom: 16px;
	margin-bottom: 60px;
	line-height: 43.2px;
}

section.testimonials h2 {
	font-size: 48px;
	font-weight: 700;
	text-align: center;
	position: relative;
	padding-bottom: 16px;
	margin-bottom: 20px;
}

section.services {
	padding-top: 60px;
	background: #ffffff;
}

.story-card {
	width: 442px;
	height: 337px;
	border-radius: 10px;
	padding: 10px;
	text-align: left;
}

.story-card>div {
	background-color: white;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	padding: 30px 40px;
	display: flex;
	flex-direction: column;
}

.story-image {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	position: relative;
}

.story-text {
	padding-left: 20px;
	border-left: 1px solid #09C3FF;
	color: #727885;
	font-size: 16px;
	font-weight: 400;
	margin: 25px 0px;
	overflow-y: auto;
	height: 40px;
	flex: 1;
}

.story-text::-webkit-scrollbar {
	display: none;
}

.story-by {
	color: #1B2E59;
	font-size: 18px;
	font-weight: 700;
}

.story-name {
	color: #727885;
	font-size: 12px;
	font-weight: 700;
}

.services-container {
	max-width: 450px;
	margin-left: auto;
	margin-right: auto;
	background: #fff;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 60px;
	text-align: center;
}

section.features .grid_container_3 {
	margin-bottom: 30px;
}

section.features .grid_container_3,
section.features .grid_container_2 {
	gap: 99px;
}

.feature-item {
	display: flex;
	align-items: center;
	border: 1px solid #004AAD;
	border-radius: 41px;
	padding: 0px 30px;
	box-shadow: 0px 4px 11px 0px #00000040;
	color: #212529;
	font-size: 24px;
	font-weight: 600;
	line-height: 31px;
	gap: 30px;
	min-width: 330px;
	max-width: 363px;
	margin: auto;
}

.grid_container_2 .feature-item:nth-of-type(1) {
	margin-left: calc(100% - 330px);
}

.grid_container_2 .feature-item:nth-of-type(2) {
	margin-right: calc(100% - 330px);
}

.rowCenter {
	display: flex;
	align-items: center;
}

.gap_50 {
	gap: 50px;
}

section.faqs {
	background-image: linear-gradient(to top right, #3a72bf, #0d53ad);
}

section.faqs h2 {
	text-align: center;
	position: relative;
	padding-bottom: 16px;
	color: #ffffff;
}

p.features-quote {
	text-align: center;
	margin-bottom: 30px;
}

p.testimonials-quote {
	text-align: center;
	margin-bottom: 0px;
}

p.faqs-quote {
	text-align: center;
	margin-bottom: 50px;
	color: #ffffff;
}

/* section.faqs h2:after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: #0d53ad;
	bottom: 0;
	left: calc(50% - 25px);
} */

/* section.features h2:after {
	content: "";
	position: absolute;
	display: block;
	width: 50px;
	height: 3px;
	background: #0d53ad;
	bottom: 0;
	left: calc(50% - 25px);
} */

img.features-img {
	width: 40px;
	margin: 10px 0px;
}

p.features-p {
	font-size: 28px;
	font-weight: 700;
	margin-top: 20px;
}

.items {
	width: 90%;
	margin: 0px auto;
	margin-top: 60px;
}


.slick-slide img {
	width: 100%;
	border: 0px solid #fff;
}

.slick-dotted.slick-slider {
	margin-top: 30px;
}


p.quote-body {
	background: #ffffff;
	padding: 15px;
	border-radius: 5px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;
	font-size: 18px;
	line-height: 30px;
	font-style: italic;
	color: #334155;
}

span.material-icons.q-rev {
	transform: rotate(181deg);
}

p.quote-body span.material-icons {
	color: #5e4690;
}

.accordion {
	max-width: 900px;
	margin: auto;
}

.accordion-button:not(.collapsed) {
	background: #fff;
	color: #000000;
}

.accordion-button {
	background: #fff;
	color: #000000;
	font-weight: 700;
}

.accordion-item {
	margin-bottom: 20px;
	border-radius: .25rem
}

.accordion-button {
	padding: 30px 20px;
}

p.contact-quote {
	text-align: center;
	margin-bottom: 30px;
}

section.contact h2 {
	font-size: 48px;
	font-weight: 700;
	text-align: center;
	position: relative;
	padding-bottom: 12px;
	line-height: 43.2px;
}

.address,
.email,
.phone {
	background: #ffffff;
	padding: 40px;
	text-align: center;
	border-radius: 5px;
	box-shadow: rgb(100 100 111 / 20%) 0px 7px 10px 0px;

}

.address {
	margin-top: 15px;
}

.address p,
.email p,
.phone p {
	margin: 0
}

.address h3,
.email h3,
.phone h3 {
	font-weight: 700;
}

.address i,
.email i,
.phone i {
	font-size: 40px;
	color: #0d53ad;
	margin-bottom: 20px;

}

.create-value {
	padding: 180px 0px 20px 0px;
}

h2.lasting-values {
	/* padding-top: 60px; */
	color: #212529;
}

img.features-image {
	padding: 0 60px;
}

.whatsapp-contact {
	position: fixed;
	bottom: 80px;
	right: 20px;
	z-index: 999;
}

.whatsapp-contact img {
	width: 80px;
}
/* Main wrapper container */
.chat-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    gap: 20px; /* Adds spacing between WhatsApp icon and ChatBot */
}

/* WhatsApp contact icon styling */
.whatsapp-contact img {
    width: 80px;
}

/* Chat bot container styling */
.chat-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 400px;
    height: 400px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
}

.why-us a {
	margin-top: 40px;
	width: 220.69px;
}

.services-container a {
	text-decoration: auto;
}

.social-icons {
	width: 30px;
	height: 26px;
}

.detail-list {
	padding-top: 20px;
}

.inner-container h3 {
	margin-top: 30px;
}

.inner-container h3 a {
	color: #282529;
}

.inner-container .service-container h3 {
	color: #282529;
}

.footer-bottom {
	margin-top: 20px;
}

.slider-images {
	width: 100%;
	height:70%;
   margin: 10px;
	
}

.anchor-text {
	text-decoration: none;
	color: #212529;
}

.follow-icons {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-detail {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.navbar-toggler-icon {
	background-image: none !important;
	border: none;
}

.navbar-toggler {
	border: none;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #004AAD;
	padding: 12px;
}

.truncate_lines_3 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.truncate_lines_2 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	height: 5rem;
	margin-bottom: 16px;
}

.hover_link:hover {
	cursor: pointer;
	text-decoration: underline;
}

@media only screen and (max-width: 991.98px) {
	section.herosection {
		padding: 40px 0px;
		background-image: none;
	}
	.chat-wrapper {
		bottom: 52px;
		right: 3px;
	}
	.item-wrap {
		flex-wrap: wrap;
		justify-content: center;
	}

	section.herosection h1 {
		padding-top: 20px;
		font-size: 34px;
		margin-bottom: 20px;
	}

	img.hero-image {
		padding: 40px 60px;
	}

	section.why-us {
		padding: 40px 0px;
	}

	.why-us a {
		margin-top: 20px;
	}

	section.projectd-completed {
		padding: 40px 0px;
	}

	section.features {
		padding: 40px 0;
	}

	p.features-p {
		margin-top: 16px;
	}

	section.banner-text {
		padding: 40px 0px;
	}

	section.services {
		padding-top: 40px;
	}

	section.services h2 {
		margin-bottom: 40px;
	}

	.services-info h3 {
		font-size: 20px;
	}

	.services-container {
		margin-bottom: 40px;
	}

	section.testimonials {
		padding: 40px 0px;
	}

	section.faqs {
		padding: 40px 0;
	}

	section.contact {
		padding: 40px 0px;
	}

	p.contact-quote {
		margin-bottom: 20px;
	}

	.address,
	.email,
	.phone {
		padding: 63px;
	}

	.address {
		margin-top: unset;
	}

	.box-with-shadow {
		padding: 10px;
	}

	.story-text {
		font-size: 14px;
	}

}

@media screen and (max-width: 767.98px) {

	/* nav.navbar.navbar-expand-sm.bg-light.custom-nav-bar.navbar-light.fixed-top {
		background: #d0fafc !important;
	} */

	nav.navbar.navbar-expand-sm.sticky-bg.bg-light.custom-nav-bar.navbar-light.fixed-top {
		background: #fff !important;
	}

	.hero-cta {
		margin-bottom: 50px;
	}

	.navbar-toggler {
		width: 32px;
		height: 32px;
		padding: 1px;
	}

	section.blog h3 {
		font-size: 24px;
		font-weight: 600;
		line-height: 31px;
	}

	.truncate_lines_2 {
		height: 6rem;
	}

	.items {
		width: 90%;
		margin-top: 16px;
	}

	.create-value {
		padding: 50px 0px 40px 0px;
	}

	.responsive_img {
		display: inline-block;
	}

	.responsive-social-media {
		display: flex;
		font-size: 13px;
		line-height: 15.23px;
		gap: 16px;
	}

	.responsive-social-media div {
		gap: 10px;
	}

	.social-media {
		display: none;
	}

	.so-far {
		margin: 0px;
		border-radius: 5px;
	}

	.feature-item {
		font-size: 16px;
	}

	.custom-nav-bar a.nav-link {
		font-size: 13px;
		font-weight: 400;
	}

	section.herosection {
		padding: 20px 0px;
	}

	.social-media {
		font-size: 13px;
	}

	.why-us a {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	section.projectd-completed {
		padding: 20px 0px;
	}

	p.count {
		font-size: 34px;
	}

	section.projectd-completed p.p-title {
		display: block;
		border-bottom: 1px solid #fff;
		width: fit-content;
		text-align: center;
		margin: auto;
		margin-bottom: 40px;
	}

	section.projectd-completed {
		text-align: center;
	}

	section.features {
		padding: 20px 0;
	}

	p.features-p {
		font-size: 20px;
		margin-top: 20px;
		margin-left: 20px;
	}

	section.banner-text {
		padding: 20px 0px;
	}

	section.banner-text h4{
		font-size: 24px;
	}

	section.services {
		padding-top: 20px;
	}

	section.services h2 {
		margin-bottom: 0px;
		font-size: 26px;
	}

	.services-info h3 {
		margin-top: 5px;
	}

	.services-container {
		margin-bottom: 20px;
	}

	section.testimonials {
		padding: 20px 0px;
	}

	section.testimonials h2 {
		font-size: 26px;
		margin-bottom: 0px;
	}

	section.testimonials .items {
		margin-top: 40px;
	}

	section.faqs {
		padding: 20px 0;
	}

	section.faqs h2 {
		font-size: 26px;
	}

	section.contact {
		padding: 20px 0px;
	}

	section.contact h2 {
		font-size: 26px;
	}

	.address,
	.email,
	.phone {
		margin-bottom: 25px;
	}

	.icons-follow {
		padding-top: 20px;
	}

	img.hire-image {
		padding: 0 60px;
	}

	.inner-container img.hire-image {
		padding: 0;
	}

	section.services img {
		max-width: 231px;
	}

	.whatsapp-contact img {
		width: 40px;
	}

	.whatsapp-contact {
		right: 5px;
		bottom: 5px;
	}

	.story-text {
		font-size: 13px;
	}

	.detail-list {
		padding-top: 0px;
	}

	section.features .grid_container_3 {
		margin-bottom: 25px;
	}

	section.features .grid_container_3,
	section.features .grid_container_2 {
		gap: 25px;
	}

	.feature-item {
		width: 75vw;
		max-width: none;
	}

	.grid_container_2 .feature-item:nth-of-type(1) {
		margin-left: 0px;
		margin: auto;
	}

	.grid_container_2 .feature-item:nth-of-type(2) {
		margin-right: 0px;
		margin: auto;
	}
}

@keyframes hero_transition {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}