// variables

// colors

$primary-bright: #266FF2;
$primary-light: #E6EEFB;
$secondary-bright: #FF1616;
$secondary-light: #FFFFFF;
$submit: #4285F4;
$default-hover: #0062ff;

// fonts

$bold : 700;
$semi-bold : 600;
$five-bold : 500;
$h1-size: 60px;
$h2-size: 48px;
$h3-size: 40px;
$h4-size: 34px;
$h5-big-size: 24px;
$h5-small-size: 16px;
$h6-size: 14px;