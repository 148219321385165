@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

.navbar-light .navbar-brand img {
  width: 180px;
}

.navbar {
  min-height: 68px;
}

a.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}

.belownav {
  min-height: 68px;
}

p{
  font-size: 16px;
}

p,
h1,
h2,
h3,
li,
a {
  font-family: 'Roboto', sans-serif;
}

h6 {
  font-size: 18px;
  margin-left: 15px;
}

section.footer {
  padding-top: 60px;
  background-image: linear-gradient(to top right, #0C0833, #0C0833);
  color: #ffffff;
}

section.footer h3 {
  margin-bottom: 30px;
}

.footer li a {
  color: #ffffff;
}
.footer li div {
  color: #ffffff;
  cursor: pointer;
}

.footer li {
  margin-bottom: 10px;
}

.copyright {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  color: #fff;
}

section.breadcrumb-section {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

ol.breadcrumb {
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  display: flex;
  justify-content: center;
}

li.breadcrumb-item a {
  text-decoration: none;
  color: #ffffff;
}

li.breadcrumb-item.active {
  text-decoration: none;
  color: #ffffff;
}

.breadcrumb-item+.breadcrumb-item::before {
  font-family: 'Font Awesome';
  content: "\00BB" !important;
  color: #ffffff !important;
}

.inner-container {
  padding-bottom: 24px;
}

.inner-container .contact {
  padding-top: 0px;
}

.inner-container h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 20px;
}
.bggray{
  background: linear-gradient(180deg, rgba(201, 159, 227, 0.26) 0%, rgba(180, 207, 243, 0.26) 41.29%, rgba(204, 162, 237, 0.26) 96.24%);
}
.bggray h3{
  font-weight:600;
  font-size:48px;
  line-height:56px;
}
.bggray p{
  font-weight:400;
  font-size:24px;
  line-height:28px;
  color:#000;
  margin-top:8px;
}
.bggray .flex-item-erp{
  display:flex;
  flex-direction: column;
  justify-content:center;
  height:100%;
}
.bggray_1 {
  background: linear-gradient(
    to right,
    #B4CFF3 20%,
    transparent 70%
  );
}
.bggray_2{
  background: linear-gradient(
    to left, /* Change the direction to left */
    #B4CFF3 20%,
    transparent 70%
  );
}
.bggray_2 .flex_item{
  display:flex;
  flex-direction:column;
  align-items:end;
}
.bggray_2 ul {
  padding-left: 0; /* Optional: Remove default padding */
}

.bggray_2 ul li {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.bggray_2 ul li svg {
  margin-right: 8px; /* Space between the icon and the text */
}
.bggray_1 ul{
  display:flex;
  flex-wrap:wrap;
  padding-left:0px;
  margin-top:16px;
}
.bggray_1 ul li{
  list-style-type: none; 
  width:40%;
  margin-bottom:16px;
  font-size:18px;
  font-weight:500;
  line-height:18px;
  display:flex;
  gap:8px;
  align-items:center;
}
.custom-list {
  list-style-type: none; /* Remove default bullets */
  padding-left:14px; /* Remove default padding */
}

.custom-list li {
  position: relative; /* Positioning for the custom arrow */
  padding-left: 20px; /* Space for the custom arrow */
}
.bggray_1 .flex-item-erp{
  display:flex;
  flex-direction: column;
  justify-content:center;
  height:100%;
}
.bggray_1 h2{
  font-weight:600;
  font-size:48px;
  line-height:56px;
}
.bggray_1 p{
  font-weight:400;
  font-size:16px;
  line-height:18px;
  margin-top:8px;
  max-width:613px;
}
.Control{
  background-image: url('./assets/images/services/bgimage.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color:#FFF;
}

.Control h4{
  font-weight:600;
  font-size:48px;
  line-height:56px;
  text-align:center;
}
.Control .grid-item{
  background-color:#FFF;
}
.ERP_Adoption h4{
  font-weight:600;
  font-size:48px;
  line-height:56px;
  text-align:center;
  margin-bottom:50px;
}
.ERP_Adoption .card{
  min-height:217px;
  width:407px;
  box-shadow: 0px 0px 11px 5px #2362B629;
  position: relative;

}
.ERP_Adoption .card .card-daetail{
  margin-top:50px;
  padding-left:16px;
  padding-right:16px;
}
.ERP_Adoption .card .card-daetail h5{
  font-size:24px;
  font-weight:600;
  line-height:28px;
}
.ERP_Adoption .card .card-daetail p{
  font-size:16px;
  font-weight:400;
  line-height:18px;
}
.ERP_Adoption .card .card-no{
  position:absolute;
  left:83%;
  background-color: #1C4D8F;
  padding-top:13px;
  padding-left:23px;
  padding-right:29px;
  padding-bottom:23px;
  border-radius:0 5px 0 35px;
  color:#FFF;
}
.ERP_Adoption .flex-item-container{
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
  align-items:center;
  gap:30px;
}
.Control .container{
  margin-top:50px;
}
.inner-container h2:after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 3px;
  background: #0d53ad;
  bottom: 0;
  left: calc(50% - 25px);
}
.Amplify h2{
  text-align:center;
  font-weight:600;
  font-size:48px;
  line-height:56px;
  margin-bottom:50px;
}
.Amplify .card_1{
  min-height:138px;
  width:100%;
  border-bottom: 2px solid #266FF2;
  display:flex;
  margin-bottom:32px;
  flex:1;
}
.Amplify .card_2{
  min-height:138px;
  width:100%;
  border-bottom: 2px solid #266FF2;
  display:flex;
  flex-direction:column;
  justify-content:center;
  margin-bottom:32px;
  flex:1;
}
.Amplify .card_2 .flex_row{
  display:flex;
  align-items:center;
  gap:32px;
}
.Amplify .card_1 .one {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 44px;
  align-items: center;
  flex: 1;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color:#CCC;
}

.Amplify .card_2 .two {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
}

.Amplify .card_1 .card_1_detail {
  flex: 1;
  color: #000;
  padding: 24px;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

/* Hover effect to remove background color */
.Amplify .card_1:hover .one {
  background-color: #E6EEFB; /* Remove background color on hover */
  color:#000;
}

.Amplify .card_1:hover .card_1_detail {
  background-color: #14344B;/* Remove background color on hover */
  color: #FFF; /* Change text color to black for better visibility */
}

.Business{
  background-color:#E6EEFB;
}
.Business h4{
  font-weight:600;
  font-size:48px;
  line-height:56px;
  text-align:center;
  margin-bottom:50px;
}
.Business .grid-item{
  background-color:#FFF;
}
img.mw-50 {
  max-width: 50%;
}

img.mw-60 {
  max-width: 60%;
}

img.mw-70 {
  max-width: 70%;
}

img.mw-80 {
  max-width: 80%;
}

img.mw-90 {
  max-width: 90%;
}

.service-container {
  text-align: center;
}

.service-container img {
  max-width: 400px;
  aspect-ratio: 905 / 704;
}

.service-container h3 {
  margin-bottom: 40px;
}

.services-grid a {
  text-decoration: auto;
}

.footer a {
  text-decoration: auto;
}

@media only screen and (max-width: 991.98px) {

  section.contact h2 {
    font-size: 26px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  section.footer {
    padding-top: 40px;
  }

  section.footer h3 {
    margin-bottom: 30px;
    font-size: 20px;
  }

  section.footer h4 {
    font-size: 20px;
  }

  .copyright {
    margin-top: 40px;
  }

  img.mw-50 {
    max-width: 100%;
  }

  img.mw-60 {
    max-width: 100%;
  }

  img.mw-70 {
    max-width: 100%;
  }

  img.mw-80 {
    max-width: 100%;
  }

  img.mw-90 {
    max-width: 100%;
  }

  .service-container img {
    max-width: 250px;
  }
}

@media screen and (max-width: 767.98px) {
  .navbar-light .navbar-brand img {
    width: 130px;
  }

  ol.breadcrumb {
    font-size: 13px;
  }
  .Amplify h2 {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 50px;
}
.bggray_1 h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 56px;
}
.bggray_1 ul li {
  width: 50%;
}
  .Business h4{
    font-weight:600;
    font-size:24px;
    line-height:normal;
    text-align:center;
    margin-bottom:50px;
  }
  p {
    font-size: 13px;
  }

  section.footer {
    padding-top: 20px;
  }

  section.footer h3 {
    margin-bottom: 20px;
  }


}