.chat-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 400px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    z-index:1000;
  }
  .bot-icon {
    flex-shrink: 0; /* Prevent the icon from shrinking */
    height: 20px; /* Set a consistent height */
    width: 20px; /* Set a consistent width */
}
  .chat-header {
    background: linear-gradient(to right, #1e3a8a, #3b82f6);
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .header-text {
    flex-grow: 1;
  }
  
  .header-text h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }
  
  .header-text p {
    margin: 0;
    font-size: 14px;
    opacity: 0.8;
  }
  
  .close-button {
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .chat-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
    background-color: #f0f2f5;
  }
  .chat-body::-webkit-scrollbar {
    width: 6px;
  }
  
  .chat-body::-webkit-scrollbar-track {
    background: #f0f2f5;
  }
  
  .chat-body::-webkit-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 3px;
  }
  
  /* For Internet Explorer */
  .chat-body {
    -ms-overflow-style: none;
  }
  
  .chat-body::-ms-scrollbar {
    width: 6px;
  }
  
  .chat-body::-ms-scrollbar-track {
    background: #f0f2f5;
  }
  
  .chat-body::-ms-scrollbar-thumb {
    background-color: #bbb;
    border-radius: 3px;
  }
  .avatar-container {
    position: relative; /* Create a positioning context for the dot */
}

.avatar {
    width: 50px; /* Adjust width as necessary */
    height: 50px; /* Adjust height as necessary */
    border-radius: 50%; /* Make the image circular */
}

.status-indicator {
    position: absolute;
    bottom:1px; /* Position the dot at the bottom of the avatar */
    right:12px; /* Position the dot to the right */
    width: 10px; /* Width of the dot */
    height: 10px; /* Height of the dot */
    background-color: green; /* Green color for the dot */
    border-radius: 50%; /* Make the dot circular */
    border: 2px solid white; /* Optional: white border around the dot */
}

  .message {
    margin-bottom: 15px;
    display: flex;
  }
  
  .message.bot {
    justify-content: flex-start;
  }
  
  .message.user {
    justify-content: flex-end;
  }
  
  .message-content {
    max-width:100%;
    padding: 10px 15px;
    border-radius: 18px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .bot .message-content {
    background-color: white;
    color: #333;
    display:flex;
    align-items:center;
    gap:10px;
  }
  
  .user .message-content {
    background-color: #0084ff;
    color: white;
  }
  
  .option {
    display: inline-block;
    margin: 5px;
    padding: 8px 12px;
    background-color: #e4e6eb;
    color: #050505;
    border-radius: 18px;
    cursor: pointer;
  }
  
  .option.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .back-button-container{
    display: flex !important;
    justify-content: center !important;
    flex: 1;
}
  .chat-input {
    display: flex;
    padding: 10px;
    background-color: white;
    border-top: 1px solid #e5e5e5;
  }
  
  .chat-input input {
    flex-grow: 1;
    border: none;
    padding: 10px;
    font-size: 14px;
    border-radius: 20px;
    background-color: #f0f2f5;
  }
  
  .chat-input button[type="submit"] {
    background-color: #0084ff;
    color: white;
    border: none;
    padding: 10px 15px;
    margin-left: 10px;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .attachment-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 0 10px;
  }
  
  .chat-toggle {
    background-color: #007bff; /* Button color */
    color: white; /* Icon color */
    border: none; /* Remove border */
    border-radius: 50%; /* Round button */
    padding: 10px; /* Padding for better click area */
    cursor: pointer; /* Pointer on hover */
    display: flex; /* Align icon in the center */
    justify-content: center; /* Center the icon horizontally */
    align-items: center; /* Center the icon vertically */
}

.chat-toggle:hover {
    background-color: #0056b3; /* Darker shade on hover */
}
  @media (max-width: 768px) {
    .chat-container {
        width: 100%;
        height: 91vh;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }
}