@import './variables.scss';

* {
	font-family: 'Roboto', sans-serif;
}

// Navbar styles

.custom-nav-bar a.nav-link.active {
	color: $secondary-bright;
}

.custom-nav-bar a.nav-link {
	font-weight: 600;
	line-height: 16px;
}

// buttons

.btn-secondary {
	background-color: $secondary-bright;
	border: 1px solid $secondary-bright;
}

.btn-primary {
	border-color: $primary-bright;
	background: $primary-bright;
}

.btn-primary,
.btn-default {
	font-size: $h6-size;
	font-weight: $bold;
	padding: 15px 42px;
	line-height: 30px;
	letter-spacing: 1.4px;
	text-transform: uppercase;
}

.btn-primary:hover {
	background: $default-hover;
}

.color-primary {
	color: $primary-bright;
}

.btn-default {
	border-color: $primary-bright;
	color: $primary-bright;
}

.btn-default:hover {
	color: #ffffff;
	background: $default-hover;
}

// breadcrumb

.breadcrumb-section {
	h2 {
		color: white;
		font-size: $h2-size;
		font-weight: $semi-bold;
		line-height: 56.25px;
	}
}

// home

section.herosection {
	p {
		line-height: 20px;
	}
}

.create-value {
	.col-md-6:nth-child(1) {
		padding-right: 89px;
	}

	.col-md-6:nth-child(2) {
		padding-left: 89px;
	}

	p {
		line-height: 26px;
	}

	h2 {
		font-size: $h2-size;
		font-weight: $semi-bold;
	}
}

section.features h2 {
	text-align: center;
	position: relative;
	margin-bottom: 80px;
	line-height: 43.2px;
	font-size: $h2-size;
	font-weight:
		$bold;
}

section.why-us {
	padding: 80px 0px;
	background-color: $primary-light;

	.col-md-6 {
		img {
			max-width: none;
		}
	}
}

section.ai {
	background-color: $primary-light;
}

section.ai .bg_img {
	background-image: url('../assets/images/core/ai.webp');
	height: 334px;
	background-size: cover;
	background-repeat: no-repeat;
	color: #ffffff;

	h3 {
		font-size: 32px;
		font-weight: $bold;
		padding-right: 90px;
		line-height: 38.75px;
	}

	display: flex;
}

section.blog {
	.row {
		margin: 80px 0px;
	}
}

.blog-image {
	width: 100%;
	max-height: 396px;
	margin-bottom: 24px;

	img {
		width: 100%;
		filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.25));
		aspect-ratio: 617/396;
	}
}

section.testimonials {
	padding: 60px 0px;
	background: $primary-light;
}

section.success {
	// padding: 130px 0px;
	background: $primary-light;
}

section.success {
	span {
		color: $primary-bright;
		text-decoration: underline;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 14px;
		line-height: 15.4px;
	}
}

section.contact {
	padding: 60px 0px;
	background: $primary-light;

	.btn-primary {
		text-transform: none;
		letter-spacing: unset;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}
}

section.newproject {
	background-image: url('../assets/images/newproject_1.png');
	background-repeat: no-repeat;
	background-size: 477px 510px;
	background-color: #2362B6;
	height: 815px;
	color: #ffffff;
	display: flex;
	align-items: center;
	background-position: center;
	padding-bottom: unset;
	position: relative;

	.btn-default {
		text-transform: none;
		letter-spacing: unset;
		line-height: 16px;
		font-size: 16px;
		font-weight: 600;
	}
}

.ellipse_top {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $primary-light;
	clip-path: ellipse(58% 20% at 50% -10%);
	;
}

.ellipse_bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #0C0833;
	clip-path: ellipse(58% 20% at 50% 110%);
}


section.newproject p {
	font-size: $h5-small-size;
	font-weight: $semi-bold;
	text-transform: uppercase;
}

section.newproject h3 {
	font-size: $h3-size;
	font-weight: $bold;
	margin-bottom: 30px;
	line-height: 50px;
}

.quotes {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	border: 1px solid $primary-light;
	right: 0;
	top: 0;
	z-index: 5;
	background: white;
}

section.about-us {
	h2 {
		margin-bottom: 56px;
	}

	h3 {
		font-size: $h2-size;
		font-weight: 500;
		margin-bottom: 24px;
	}

	p {
		font-weight: 400;
	}

	.col-md-6 {
		padding: 0px;
	}
}

section.customer-centric,
section.timebox,
section.why-choose {
	background-color: $primary-light;
}

section {
	h2 {
		font-size: $h2-size;
		font-weight: $five-bold;
		line-height: 60px;
	}

	p {
		line-height: 24px;
	}

	.col-md-6>img {
		max-width: 100%;
	}

	.col-md-6 {
		display: flex;
		flex-direction: column;
		justify-content: center;

		h2 {
			margin-bottom: 24px;
			line-height: 38.4px;
			font-weight: $five-bold;
			font-size: $h2-size;
		}
	}

	@media screen and (max-width: 767.98px) {
		p {
			line-height: 19px;
		}
	}
}

section.ecosystem {
	background-color: $primary-light;

	.col-md-6 {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.col-md-6 img {
		margin-bottom: 24px;
	}
}

.grid_3 {
	display: grid;
	grid-template-columns: repeat(3, minmax(200px, 1fr));
	grid-template-rows: repeat(2, minmax(200px, 1fr));
	gap: 40px;
	justify-items: center;
	margin-top: 50px;
}

section.what-we-do,
section.dm-we-do {

	.card {
		border-radius: 10px;
		background-color: #ffffff;
		padding: 30px;

		img {
			width: 190px;
			margin: auto;
		}

		h5 {
			font-size: $h5-big-size;
			font-weight: 500;
			margin-bottom: 16px;
		}

		p {
			font-weight: 400;
			padding-left: 8px;
			border-left: 1px solid #2362B6;
		}
	}

	.card:hover {
		box-shadow: 0px 4px 16px 0px #00000036;
	}

	background-image: linear-gradient(to bottom, $primary-light 0%, $secondary-light 100%);
}

h3 {
	line-height: 28.13px;
	font-weight: 600;
}

p {
	line-height: 18.75px;
}

section.dm-top {
	.col-md-6 {
		display: flex;
		flex-direction: column;
		justify-content: center;

		h3 {
			font-size: $h5-big-size;
			font-weight: $semi-bold;
		}

		p {
			font-size: $h5-small-size;
			font-weight: 400;
		}
	}
}

section.dm-slider {
	background-image: linear-gradient(to bottom, $secondary-light 0%, $secondary-light 20%, $primary-light 20%, $secondary-light 100%);

	.container>img {
		width: 100%;
		margin-bottom: 86px;
	}

	position: relative;

	.slick-prev,
	.slick-next {
		display: none;
	}
}

.dm-btn-parent {
	position: absolute;
	bottom: 20px;
	right: 61.667px;
	display: flex;
	gap: 20px;

	.btn {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: #0d99ff4d;
		border: 1px solid $primary-bright;
	}
}

.home-btn-pair {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
	margin-top: 60px;

	.btn {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-color: white;
	}
}

.box-with-shadow {
	h2 {
		font-size: 36px !important;
	}

	h2::after {
		display: block;
		margin: auto;
		content: '';
		width: 50px;
		height: 3px;
		background-color: #0D53AD;
		margin-top: 14px;
	}
}

.responsive_img {
	img {
		width: 100%;
		padding-left: 6%;
	}

	padding-top: 0px;
	padding-bottom: 0px;
}

@keyframes translateAnimation {
	0% {
		transform: translateY(10%);
	}

	100% {
		transform: translateY(-100%);
	}
}

.story-text:nth-child(1)>p {
	animation: translateAnimation 12s linear infinite;
}

.story-text:nth-child(2)>p {
	animation: translateAnimation 20s linear infinite;
}

.dm-card {
	h5 {
		font-size: $h5-big-size;
		font-weight: $semi-bold;
		padding-bottom: 16px;
		border-bottom: 0.5px solid black;
		margin-bottom: 16px;
	}

	p {
		font-weight: 400;
		font-size: $h5-small-size;
		display: flex;
		gap: 8px;
		align-items: center;
		line-height: 19px;
	}

	p:before {
		content: "";
		width: 3px;
		height: 95px;
		border-radius: 0px 13px 13px 0px;
		border: 1px solid #407BFF80
	}

	cursor: pointer;
}

.dm-card:hover>div {
	box-shadow: 0px 5px 8px 0px #00000040;
}

.dm-dots {
	button:before {
		color: $primary-bright !important;
	}

	li {
		margin: 0px;
	}
}

section.dm-we-do {
	h5 {
		border-bottom: 0.5px solid black;
		padding-bottom: 16px;
	}

	.card {
		img {
			margin: 30px auto;
		}
	}

	.grid_3 {
		gap: 20px;
	}
}

@media screen and (max-width: 767.98px) {
	section {
		h2 {
			font-size: $h5-big-size;
			font-weight: $bold;
			line-height: 30px;
		}
	}

	section {
		.col-md-6 {
			h2 {
				font-size: $h5-big-size;
				font-weight: 500;
				line-height: 28.13px;
			}
		}
	}

	section.blog {
		.row {
			margin: 10px 0px;
		}
	}

	section.dm-top .col-md-6 {
		h3 {
			font-size: 18px;
			line-height: 21.09px;
		}

		p {
			font-size: 13px;
			line-height: 15.23px;
		}
	}

	section.why-us {
		padding: 40px 0px;

		h2 {
			padding-top: 40px;
			font-size: 24px;
			font-weight: 600;
		}
	}

	.dm-btn-parent {
		.btn {
			width: 24px;
			height: 24px;
			padding: 0px;
		}
	}

	.home-btn-pair {
		margin-top: 30px;
	}

	.grid_3 {
		grid-template-columns: repeat(2, minmax(200px, 1fr));
	}

	.btn-primary,
	.btn-default {
		font-size: 13px;
		font-weight: 700;
		padding: 8px 15px;
	}

	section.about-us {
		.col-md-6 {
			padding: 0px 12px;
		}
	}

	section.features h2 {
		font-size: $h5-big-size;
		font-weight: $semi-bold;
		margin-bottom: 30px;
	}

	section.ai {
		padding: 50px 0px;

		.container {
			padding: 0px;
		}

		.bg_img {
			h3 {
				font-size: 24px;
				padding: 10px 40px;
				line-height: 29.06px;
			}

			background-image: url('../assets/images/ai_responsive.webp');
			height: 408px;

			div {
				align-items: end !important;
				max-width: 100vw;
			}
		}
	}

	section.timebox {
		.row {
			flex-direction: column-reverse;
		}
	}

	.breadcrumb-section {
		h2 {
			font-size: $h5-big-size;
			line-height: 52.8px;
		}
	}

	.create-value {
		.col-md-6:nth-child(1) {
			padding: 0px 12px;

			img {
				max-width: 90%;
				margin: auto;
			}
		}

		.col-md-6:nth-child(2) {
			padding: 0px 12px;
		}
	}

	section.newproject {
		h3 {
			font-size: $h5-big-size;
			line-height: 31.2px;
		}

		p {
			font-size: 13px;
		}
	}

	.responsive_img img {
		padding-left: 0px;
	}

	.box-with-shadow {
		h2 {
			font-size: 24px !important;
		}
	}
}

@media screen and (max-width: 575.98px) {
	.grid_3 {
		grid-template-columns: repeat(1, minmax(200px, 1fr));
	}

	section.newproject {
		background-size: 334px 361px;

		.ellipse_top {
			clip-path: ellipse(75% 20% at 50% -15%);
		}

		.ellipse_bottom {
			clip-path: ellipse(75% 20% at 50% 95%);
		}

		.container {
			align-self: flex-start;
		}
	}
}