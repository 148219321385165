@charset "UTF-8";
.domain-card {
  padding: 15px 0px 15px 50px;
  border-radius: 10px;
  border: 2px solid #A8D6F6;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.domain-card:hover {
  border: 2px solid #2362B6;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1607843137);
}
.domain-card:hover .img-container .overlay {
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
}

.domain-card .img-container {
  width: 387px;
  height: 251px;
  margin-right: 60px;
  border-radius: 5px;
  position: relative;
}
.domain-card .img-container img {
  width: 100%;
}
.domain-card .img-container .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(20, 52, 75, 0.7803921569);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  color: white;
  line-height: 21px;
  font-size: 13px;
  font-weight: 400;
  padding-left: 28px;
  padding-right: 28px;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.index {
  background-color: #2362B6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 23px;
  color: white;
  font-size: 40px;
  font-weight: 500;
  border-radius: 10px;
  width: 100px;
  height: 94px;
  margin-right: 28px;
}

.arrow {
  padding: 15px 65px;
  border-left: 1px solid #A8D6F6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 251px;
  cursor: pointer;
}

.domain-parent {
  padding-top: 150px;
  padding-bottom: 100px;
  background: linear-gradient(180deg, rgba(201, 159, 227, 0.26) 0%, rgba(180, 207, 243, 0.26) 10%, rgba(255, 255, 255, 0.26) 50%, rgba(255, 255, 255, 0.26) 100%);
}

.h_32_500 {
  font-size: 32px;
  font-weight: 500;
  line-height: 37.5px;
}

.rowBetweenCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rowStartCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rowCenter {
  display: flex;
  align-items: center;
}

.flex_1 {
  flex: 1;
}

section.healthcareit h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 8px;
  text-align: center;
}
section.healthcareit p {
  line-height: 19px;
}
section.healthcareit .col-md-4 {
  background-image: url(http://localhost:3000/static/media/one.f6afe59….webp);
  height: 353px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  align-items: end;
}
section.healthcareit .card {
  padding: 32px 24px;
  box-shadow: 0px 4px 15px 0px rgba(33, 93, 173, 0.2509803922);
  width: 355px;
  height: 227px;
  background-image: url("../../assets/images/services/card_bg.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
}
section.healthcareit .card h4 {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.13px;
}
section.healthcareit .card p {
  line-height: 19px;
}
section.healthcareit .one {
  background-image: url("../../assets/images/services/one.webp");
}
section.healthcareit .two {
  background-image: url("../../assets/images/services/two.webp");
}
section.healthcareit .three {
  background-image: url("../../assets/images/services/three.webp");
}
@media screen and (max-width: 767.98px) {
  section.healthcareit h2 {
    font-size: 24px;
  }
}

section.who {
  background-image: url("../../assets/images/services/Section.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 0px;
}
section.who h2 {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  color: white;
  margin-bottom: 50px;
  line-height: 56.25px;
}
section.who .grid-item {
  background-color: white;
  padding: 13px 70px;
}
section.who ul {
  list-style: none;
  display: grid;
  grid-template-columns: 300px 300px;
  padding: 0px;
  row-gap: 32px;
  column-gap: 216px;
  justify-content: center;
}
section.who ul li {
  color: white;
  padding-left: 30px;
  position: relative;
  font-weight: 500;
}
section.who ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/services/arrow.webp");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  section.who ul {
    grid-template-columns: 300px;
    row-gap: 32px;
    column-gap: 0px;
    justify-content: center;
  }
  section.who ul li::before {
    width: 13px;
    height: 13px;
  }
  section.who h2 {
    font-size: 24px;
    line-height: 30px;
  }
  section.who .grid-item {
    padding: 13px 30px;
  }
}

section.you-name {
  background-image: url("../../assets/images/services/you_name.webp");
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 510px;
  height: 510px;
  display: flex;
  align-items: center;
}
section.you-name h3 {
  font-size: 34px;
  margin-bottom: 24px;
  line-height: 40px;
}
section.you-name ul {
  list-style: none;
  padding-left: 0px;
}
section.you-name ul li {
  padding-left: 30px;
  position: relative;
  line-height: 19px;
  margin-bottom: 16px;
}
section.you-name ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-100%);
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/services/arrow_blue.webp");
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 767.98px) {
  section.you-name {
    background-image: none;
    height: fit-content;
  }
  section.you-name h3 {
    font-size: 24px;
    text-align: center;
  }
  section.you-name ul li {
    text-align: justify;
  }
  section.you-name ul li::before {
    width: 13px;
    height: 13px;
  }
}

section.hc-solutions {
  position: relative;
  padding: 60px 0px;
}
section.hc-solutions h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 35px;
}

section.benefits {
  padding: 60px 0px;
  background-color: #E6EEFB;
}
section.benefits h2 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 50px;
}
section.benefits .grid_container_2 {
  grid-template-columns: 430px 430px;
  justify-content: center;
  column-gap: 216px;
}
section.benefits .grid-item {
  background-color: white;
}
@media screen and (max-width: 767.98px) {
  section.benefits .grid_container_2 {
    grid-template-columns: minmax(200px, 1fr);
    justify-content: center;
    column-gap: 0px;
  }
}

section.our-services {
  padding: 60px 0px;
}
section.our-services h2 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 20px;
}
section.our-services .grid_container_2 {
  margin-top: 30px;
}
section.our-services .grid-item {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 0px;
  padding: 0px;
  box-shadow: none;
  color: #212529;
  font-size: 24px;
  font-weight: 600;
  justify-content: start;
  text-align: left;
  height: 138px;
  max-height: unset;
  line-height: 28px;
  border-bottom: 2px solid #266FF2;
}
section.our-services .grid-item span {
  color: #808080;
  padding: 0px 8px;
  height: 100%;
  display: flex;
  align-items: center;
}
section.our-services .grid-item div {
  padding: 24px;
  height: 138px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.our-services .grid-item h3 {
  font-size: 24px;
  margin-bottom: 0px;
}
section.our-services .grid-item p {
  font-weight: 400;
  line-height: 19px;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0;
  visibility: hidden;
  height: 0px;
  transition: opacity 1s linear, visibility 0.3s linear;
}
section.our-services .grid-item:hover span {
  background-color: #E6EEFB;
}
section.our-services .grid-item:hover div {
  background-color: #14344B;
  color: white;
}
section.our-services .grid-item:hover p {
  opacity: 1;
  visibility: visible;
  height: unset;
  margin-top: 8px;
}
@media screen and (max-width: 767.98px) {
  section.our-services .grid-item {
    height: unset;
    min-height: 138px;
  }
  section.our-services .grid-item div {
    height: unset;
    min-height: 138px;
  }
}

section.technology {
  text-align: center;
}
section.technology .grid_container_3 {
  margin-bottom: 30px;
  margin-top: 50px;
}
section.technology h3 {
  margin-bottom: 8px;
}
section.technology p {
  line-height: 19px;
}
section.technology h2 {
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.technology h2::after {
  content: "";
  width: 26%;
  height: 2px;
  background-color: #2A63B7;
  margin-top: 8px;
  margin-bottom: 16px;
}

section.banner-text.how h2 {
  margin-bottom: 50px;
  font-weight: 600;
  height: 26px;
}
section.banner-text.how .grid_container_3 {
  margin-bottom: 30px;
  column-gap: 99px;
  min-height: 82px;
}
section.banner-text.how .grid_container_2 {
  column-gap: 154px;
  min-height: 82px;
}

section.banner-text.metrics h2 {
  margin-bottom: 50px;
  font-weight: 600;
  line-height: 56px;
}
@media screen and (max-width: 767.98px) {
  section.banner-text.metrics h2 {
    line-height: 40px;
  }
}

section.banner-text.exam h2 {
  margin-bottom: 8px;
  font-weight: 600;
}
section.banner-text.exam p {
  margin-bottom: 50px;
}

section.benefits.education {
  text-align: center;
}
section.benefits.education h2 {
  margin-bottom: 9px;
  line-height: 56px;
}
section.benefits.education .grid_container_3 {
  margin-top: 50px;
  margin-bottom: 30px;
}

section.virtual h2 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 9px;
  line-height: 56px;
}
section.virtual .rss-h2 {
  margin-bottom: 50px;
}
section.virtual p {
  text-align: center;
  margin-bottom: 50px;
}
section.virtual .virtual-image {
  max-width: 490px;
}
section.virtual .rss-image {
  max-width: 350px;
}
section.virtual .rss.blue-arrows li {
  margin-bottom: 40px;
}
section.virtual .align-desktop-center {
  align-items: center;
}
@media screen and (max-width: 767.98px) {
  section.virtual .virtual-image {
    margin-bottom: 50px;
  }
  section.virtual h2 {
    line-height: 32px;
    margin-bottom: 38px;
    text-align: left;
  }
  section.virtual .rss.blue-arrows li {
    margin-bottom: 20px;
  }
  section.virtual .align-desktop-center {
    align-items: unset;
  }
}

section.sn-how {
  background: radial-gradient(circle 1000px at top right, #e8e0fc, transparent), radial-gradient(circle 500px at bottom left, #e8e0fc, transparent);
}
section.sn-how h2 {
  font-weight: 600;
}
section.sn-how img {
  height: 554px;
  width: fit-content;
}
@media screen and (max-width: 767.98px) {
  section.sn-how img {
    padding: 50px 0px;
    margin: auto;
  }
}

ul.blue-arrows {
  list-style: none;
  padding-left: 0px;
}
ul.blue-arrows li {
  padding-left: 30px;
  position: relative;
  line-height: 19px;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 500;
}
ul.blue-arrows li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-image: url("../../assets/images/services/arrow_blue.webp");
  background-size: contain;
  background-repeat: no-repeat;
}

.mobile {
  display: none !important;
}

@media screen and (max-width: 767.98px) {
  .domain-parent {
    background: none;
    padding: 21px 0px;
  }
  .domain-card {
    flex-direction: column;
    padding: 15px 0px;
  }
  .domain-card .rowBetweenCenter {
    flex-direction: column;
  }
  .domain-card .rowBetweenCenter > div {
    flex-direction: column;
  }
  .domain-card .index {
    width: 72.38px;
    height: 68px;
    font-size: 24px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .domain-card .h_32_500 {
    font-size: 24px;
  }
  .domain-card .img-container {
    margin-right: 0px;
    width: 316px;
  }
  .domain-card .arrow {
    border-left: 0;
    border-top: 1px solid #A8D6F6;
    height: 148px;
    width: 95%;
  }
  .overlay {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
  }
  ul.blue-arrows li {
    line-height: 30px;
    font-size: 16px;
  }
  ul.blue-arrows li::before {
    width: 13px;
    height: 13px;
  }
  .mobile {
    display: block !important;
  }
}