* {
  font-family: "Roboto", sans-serif;
}

.custom-nav-bar a.nav-link.active {
  color: #FF1616;
}

.custom-nav-bar a.nav-link {
  font-weight: 600;
  line-height: 16px;
}

.btn-secondary {
  background-color: #FF1616;
  border: 1px solid #FF1616;
}

.btn-primary {
  border-color: #266FF2;
  background: #266FF2;
}

.btn-primary,
.btn-default {
  font-size: 14px;
  font-weight: 700;
  padding: 15px 42px;
  line-height: 30px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}

.btn-primary:hover {
  background: #0062ff;
}

.color-primary {
  color: #266FF2;
}

.btn-default {
  border-color: #266FF2;
  color: #266FF2;
}

.btn-default:hover {
  color: #ffffff;
  background: #0062ff;
}

.breadcrumb-section h2 {
  color: white;
  font-size: 48px;
  font-weight: 600;
  line-height: 56.25px;
}

section.herosection p {
  line-height: 20px;
}

.create-value .col-md-6:nth-child(1) {
  padding-right: 89px;
}
.create-value .col-md-6:nth-child(2) {
  padding-left: 89px;
}
.create-value p {
  line-height: 26px;
}
.create-value h2 {
  font-size: 48px;
  font-weight: 600;
}

section.features h2 {
  text-align: center;
  position: relative;
  margin-bottom: 80px;
  line-height: 43.2px;
  font-size: 48px;
  font-weight: 700;
}

section.why-us {
  padding: 80px 0px;
  background-color: #E6EEFB;
}
section.why-us .col-md-6 img {
  max-width: none;
}

section.ai {
  background-color: #E6EEFB;
}

section.ai .bg_img {
  background-image: url("../assets/images/core/ai.webp");
  height: 334px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  display: flex;
}
section.ai .bg_img h3 {
  font-size: 32px;
  font-weight: 700;
  padding-right: 90px;
  line-height: 38.75px;
}

section.blog .row {
  margin: 80px 0px;
}

.blog-image {
  width: 100%;
  max-height: 396px;
  margin-bottom: 24px;
}
.blog-image img {
  width: 100%;
  filter: drop-shadow(0px 9px 12px rgba(0, 0, 0, 0.25));
  aspect-ratio: 617/396;
}

section.testimonials {
  padding: 60px 0px;
  background: #E6EEFB;
}

section.success {
  background: #E6EEFB;
}

section.success span {
  color: #266FF2;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 15.4px;
}

section.contact {
  padding: 60px 0px;
  background: #E6EEFB;
}
section.contact .btn-primary {
  text-transform: none;
  letter-spacing: unset;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

section.newproject {
  background-image: url("../assets/images/newproject_1.png");
  background-repeat: no-repeat;
  background-size: 477px 510px;
  background-color: #2362B6;
  height: 815px;
  color: #ffffff;
  display: flex;
  align-items: center;
  background-position: center;
  padding-bottom: unset;
  position: relative;
}
section.newproject .btn-default {
  text-transform: none;
  letter-spacing: unset;
  line-height: 16px;
  font-size: 16px;
  font-weight: 600;
}

.ellipse_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E6EEFB;
  clip-path: ellipse(58% 20% at 50% -10%);
}

.ellipse_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0C0833;
  clip-path: ellipse(58% 20% at 50% 110%);
}

section.newproject p {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

section.newproject h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  line-height: 50px;
}

.quotes {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #E6EEFB;
  right: 0;
  top: 0;
  z-index: 5;
  background: white;
}

section.about-us h2 {
  margin-bottom: 56px;
}
section.about-us h3 {
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 24px;
}
section.about-us p {
  font-weight: 400;
}
section.about-us .col-md-6 {
  padding: 0px;
}

section.customer-centric,
section.timebox,
section.why-choose {
  background-color: #E6EEFB;
}

section h2 {
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
}
section p {
  line-height: 24px;
}
section .col-md-6 > img {
  max-width: 100%;
}
section .col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section .col-md-6 h2 {
  margin-bottom: 24px;
  line-height: 38.4px;
  font-weight: 500;
  font-size: 48px;
}
@media screen and (max-width: 767.98px) {
  section p {
    line-height: 19px;
  }
}

section.ecosystem {
  background-color: #E6EEFB;
}
section.ecosystem .col-md-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.ecosystem .col-md-6 img {
  margin-bottom: 24px;
}

.grid_3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-template-rows: repeat(2, minmax(200px, 1fr));
  gap: 40px;
  justify-items: center;
  margin-top: 50px;
}

section.what-we-do,
section.dm-we-do {
  background-image: linear-gradient(to bottom, #E6EEFB 0%, #FFFFFF 100%);
}
section.what-we-do .card,
section.dm-we-do .card {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 30px;
}
section.what-we-do .card img,
section.dm-we-do .card img {
  width: 190px;
  margin: auto;
}
section.what-we-do .card h5,
section.dm-we-do .card h5 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
}
section.what-we-do .card p,
section.dm-we-do .card p {
  font-weight: 400;
  padding-left: 8px;
  border-left: 1px solid #2362B6;
}
section.what-we-do .card:hover,
section.dm-we-do .card:hover {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.2117647059);
}

h3 {
  line-height: 28.13px;
  font-weight: 600;
}

p {
  line-height: 18.75px;
}

section.dm-top .col-md-6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.dm-top .col-md-6 h3 {
  font-size: 24px;
  font-weight: 600;
}
section.dm-top .col-md-6 p {
  font-size: 16px;
  font-weight: 400;
}

section.dm-slider {
  background-image: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 20%, #E6EEFB 20%, #FFFFFF 100%);
  position: relative;
}
section.dm-slider .container > img {
  width: 100%;
  margin-bottom: 86px;
}
section.dm-slider .slick-prev,
section.dm-slider .slick-next {
  display: none;
}

.dm-btn-parent {
  position: absolute;
  bottom: 20px;
  right: 61.667px;
  display: flex;
  gap: 20px;
}
.dm-btn-parent .btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(13, 153, 255, 0.3019607843);
  border: 1px solid #266FF2;
}

.home-btn-pair {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 60px;
}
.home-btn-pair .btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
}

.box-with-shadow h2 {
  font-size: 36px !important;
}
.box-with-shadow h2::after {
  display: block;
  margin: auto;
  content: "";
  width: 50px;
  height: 3px;
  background-color: #0D53AD;
  margin-top: 14px;
}

.responsive_img {
  padding-top: 0px;
  padding-bottom: 0px;
}
.responsive_img img {
  width: 100%;
  padding-left: 6%;
}

@keyframes translateAnimation {
  0% {
    transform: translateY(10%);
  }
  100% {
    transform: translateY(-100%);
  }
}
.story-text:nth-child(1) > p {
  animation: translateAnimation 12s linear infinite;
}

.story-text:nth-child(2) > p {
  animation: translateAnimation 20s linear infinite;
}

.dm-card {
  cursor: pointer;
}
.dm-card h5 {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 16px;
  border-bottom: 0.5px solid black;
  margin-bottom: 16px;
}
.dm-card p {
  font-weight: 400;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  line-height: 19px;
}
.dm-card p:before {
  content: "";
  width: 3px;
  height: 95px;
  border-radius: 0px 13px 13px 0px;
  border: 1px solid rgba(64, 123, 255, 0.5019607843);
}

.dm-card:hover > div {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2509803922);
}

.dm-dots button:before {
  color: #266FF2 !important;
}
.dm-dots li {
  margin: 0px;
}

section.dm-we-do h5 {
  border-bottom: 0.5px solid black;
  padding-bottom: 16px;
}
section.dm-we-do .card img {
  margin: 30px auto;
}
section.dm-we-do .grid_3 {
  gap: 20px;
}

@media screen and (max-width: 767.98px) {
  section h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
  }
  section .col-md-6 h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.13px;
  }
  section.blog .row {
    margin: 10px 0px;
  }
  section.dm-top .col-md-6 h3 {
    font-size: 18px;
    line-height: 21.09px;
  }
  section.dm-top .col-md-6 p {
    font-size: 13px;
    line-height: 15.23px;
  }
  section.why-us {
    padding: 40px 0px;
  }
  section.why-us h2 {
    padding-top: 40px;
    font-size: 24px;
    font-weight: 600;
  }
  .dm-btn-parent .btn {
    width: 24px;
    height: 24px;
    padding: 0px;
  }
  .home-btn-pair {
    margin-top: 30px;
  }
  .grid_3 {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
  }
  .btn-primary,
  .btn-default {
    font-size: 13px;
    font-weight: 700;
    padding: 8px 15px;
  }
  section.about-us .col-md-6 {
    padding: 0px 12px;
  }
  section.features h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  section.ai {
    padding: 50px 0px;
  }
  section.ai .container {
    padding: 0px;
  }
  section.ai .bg_img {
    background-image: url("../assets/images/ai_responsive.webp");
    height: 408px;
  }
  section.ai .bg_img h3 {
    font-size: 24px;
    padding: 10px 40px;
    line-height: 29.06px;
  }
  section.ai .bg_img div {
    align-items: end !important;
    max-width: 100vw;
  }
  section.timebox .row {
    flex-direction: column-reverse;
  }
  .breadcrumb-section h2 {
    font-size: 24px;
    line-height: 52.8px;
  }
  .create-value .col-md-6:nth-child(1) {
    padding: 0px 12px;
  }
  .create-value .col-md-6:nth-child(1) img {
    max-width: 90%;
    margin: auto;
  }
  .create-value .col-md-6:nth-child(2) {
    padding: 0px 12px;
  }
  section.newproject h3 {
    font-size: 24px;
    line-height: 31.2px;
  }
  section.newproject p {
    font-size: 13px;
  }
  .responsive_img img {
    padding-left: 0px;
  }
  .box-with-shadow h2 {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 575.98px) {
  .grid_3 {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  section.newproject {
    background-size: 334px 361px;
  }
  section.newproject .ellipse_top {
    clip-path: ellipse(75% 20% at 50% -15%);
  }
  section.newproject .ellipse_bottom {
    clip-path: ellipse(75% 20% at 50% 95%);
  }
  section.newproject .container {
    align-self: flex-start;
  }
}