section.processes {
  padding-top: 100px;
  padding-bottom: 80px;
  background: linear-gradient(180deg, white, #f1edfa);
}

.process_grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
.process_grid_3 .item {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  height: 351px;
  background-position: left;
}
.process_grid_3 .item:nth-child(1) {
  background-image: url("../../assets/images/services/one.webp");
}
.process_grid_3 .item:nth-child(2) {
  background-image: url("../../assets/images/services/two.webp");
}
.process_grid_3 .item:nth-child(3) {
  background-image: url("../../assets/images/services/three.webp");
}

.process_card {
  background-color: white;
  background-image: url("../../assets/images/services/card_bg.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  max-width: 355px;
  margin-left: 80px;
  min-height: 227px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 33px 24px;
  transition: all 0.3s linear;
}
.process_card h3 {
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.process_card p {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.process_card:hover {
  box-shadow: 0px 4px 15px 0px rgba(33, 93, 173, 0.2509803922);
}

.read-more {
  font-size: 16px;
  font-weight: 600;
  color: #215DAE;
  cursor: pointer;
}

section.sales {
  background-image: url("../../assets/images/services/sales.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 519px;
}
section.sales .left {
  background-image: url("../../assets/images/services/sales_img.webp");
  background-size: cover;
  background-repeat: no-repeat;
}
section.sales .left img {
  display: none;
}

.card-auto {
  background-color: white;
  padding: 63px 29px;
  border-radius: 10px;
  margin-left: 130px;
}
.card-auto h3 {
  font-size: 24px;
  font-weight: 600;
}
.card-auto p {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}
.card-auto .btn-primary {
  background-color: #2362B6;
}

.card_item {
  border: 0.2px solid #407BFF;
  min-height: 611px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.card_item:hover {
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.2196078431);
}

.card_item .img-container {
  height: 293px;
  border-bottom: 0.2px solid #407BFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.card_item .img-container img {
  max-height: 100%;
  max-width: 100%;
}
.card_item .img-container h3 {
  font-size: 24px;
  font-weight: 500;
  color: #2362B6;
}
.card_item .img-container p {
  font-size: 16px;
  font-weight: 400;
}

.card_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  flex: 1;
}
.card_body h3 {
  color: #2362B6;
  font-size: 24px;
  line-height: 28.13px;
  margin-bottom: 24px;
}
.card_body .btn-primary {
  background-color: #2362B6;
}

.process_bottom {
  min-height: 1800px;
  background-image: url("../../assets/images/services/process_bottom.webp");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0px;
}

.half {
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 54px;
}

.plus_card {
  max-width: 575px;
  min-height: 185px;
  padding: 36px 66px 37px 44px;
  border-radius: 20px;
  position: relative;
  margin-right: 8vw;
  background-color: white;
}
.plus_card h3 {
  font-size: 24px;
  font-weight: 500;
  color: #2362B6;
}
.plus_card p {
  font-weight: 400;
}
.plus_card .icon {
  position: absolute;
  right: -33px;
  top: 35%;
  color: #2362B6;
  width: 68px;
  cursor: pointer;
}

.plus_card:hover {
  box-shadow: 0px 0px 27px 0px rgba(6, 15, 154, 0.2588235294);
}

@media screen and (max-width: 767.98px) {
  .process_grid_3 {
    grid-template-columns: repeat(1, 1fr);
  }
  section.processes {
    background: none;
  }
  .process_card h3 {
    font-size: 18px;
    line-height: 21.09px;
  }
  .process_card p {
    font-size: 13px;
    line-height: 15.23px;
  }
  .read-more {
    font-size: 13px;
  }
  .card-auto {
    margin-left: 0px;
  }
  .card-auto p {
    line-height: 16.37px;
    font-size: 13px;
  }
  .card_body h3 {
    font-size: 24px;
  }
  .card_body p {
    line-height: 15.23px;
    font-size: 13px;
  }
  .card_item {
    min-height: 496px;
  }
  .card_item .img-container {
    height: 219px;
  }
  section.sales {
    background-image: url("../../assets/images/services/sales.png");
    background-size: cover;
    height: auto;
  }
  section.sales .left {
    background-image: none;
    margin-bottom: 50px;
  }
  section.sales .left img {
    display: block;
  }
  .process_bottom {
    background-image: none;
  }
  .plus_card {
    margin: 25px 20px;
    padding: 25px 12px;
  }
  .plus_card p {
    line-height: 16.56px;
  }
  .plus_card .icon {
    transform: translate(50%, 10%);
    top: unset;
    width: 50px;
    /* bottom: -33px; */
    right: 50%;
  }
  .half:nth-of-type(2) {
    justify-content: end;
    align-items: center;
  }
  .process_bottom {
    min-height: unset;
  }
  .half {
    height: unset;
    margin-bottom: 50px;
    align-items: center;
    background: linear-gradient(180deg, #FFFFFF 0%, #ebf8fd 70%, #FFFFFF 100%);
  }
}