section.about-breadcrumb {
    background-image: url('../assets/images/about/about.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 405px;
}

section {
    padding: 60px 0px;
}

section.customer-centric {
    text-align: right;
}

section.why-choose {
    text-align: center;
}

section.why-choose img {
    max-width: 70%;
}

section.ao {
    text-align: center;
}

section.ao img {
    width: 47%;
}

section.process h2 {
    margin-bottom: 50px;
    text-align: center;
}

section.process p {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000;
}



.develop {
    background: #E6EEFB;
}

.develop p {
    text-align: center;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-bottom: 50px;
}

.develop h2 {
    margin-bottom: 8px;
    text-align: center;
}

.ecosystem-card {
    background-color: #FFF;
    box-shadow: 1px 4px 13px 2px #2362B657;
    border-radius: 20px;
    padding: 32px 24px 0 63px;
    position: relative;
    margin-bottom: 32px;
    min-height: 161px;
    max-width: 542px;
}

.ecosystem-card.padded {
    padding: 30px 56px;
    max-width: 542px;
}

.image-right {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-right img {
    width: 100%;
    height: 100%;
}

.ecosystem-card .image {
    position: absolute;
    top: 25%;
    left: -35px;
    width: 75px;
    height: 75px;
}

.ecosystem-card .image img {
    width: 100%;
    height: 100%;
}

.ecosystem-card h5 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    font-family: "Roboto";
}

.ecosystem-card p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    margin-top: 16px;
}

.ecosystem-ao {
    background-image: url('../assets/images/services/DeliverIncrementallyBackground.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
    color: #FFF;
}

.application {
    background-image: url('../assets/images/services/ImageShadow.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
}

.application h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    text-align: center;
}

.application p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.application h4 {
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
}

.application ul {
    padding-left: 0;
    /* Optional: Remove default padding */
}

.application ul li {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.application ul li svg {
    margin-right: 8px;
    /* Space between the icon and the text */
}

.Opertions {
    background: #2362B6;
    color: #FFF;
}

.Opertions ul {
    padding-left: 0;
    /* Optional: Remove default padding */
}

.Opertions h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;

}

.Opertions ul li {
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.text-center-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Opertions ul li svg {
    margin-right: 8px;
    /* Space between the icon and the text */
}

.ecosystem-ao_project {
    background-image: url('../assets/images/services/Background_2.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
    color: #FFF;
}

.ecosystem-ao_project h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
}

.ecosystem-ao_project ul {
    padding-left: 14px;
}

.ecosystem-ao_project li {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    font-family: Roboto;
}

.rodamap {
    background-image: url('../assets/images/services/Roadmap.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
    color: #FFF;
    padding-bottom: 81px;
}

.rodamap h3,
.rodamap h4,
.rodamap p {
    text-align: center;
}

.rodamap h3 {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
}

.rodamap h4 {
    font-size: 32px;
    font-weight: 700;
    line-height: 37px;
}

.rodamap h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
}

.rodamap .card {
    position: relative;
    min-height: 318px;
    width: 296px;
    background-color: #FFF;
    border-radius: 15px;
    box-shadow: 0px 4px 10px 0px #0000002E;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 50px;
}

.develop .card {
    min-height: 398px;
    min-width: 408px;
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 4px 16px 0px #2362B640;
    overflow: hidden;
    display: flex;
}

.develop h4 {
    text-align: center;
}

.develop .card {
    width: 391px;
    height: 361px;
    padding: 10px;
}

.develop .card img {
    width: 100%;
    height: 100%;
}

.image-card-wed {
    width: 140px;
    height: 140px;
}

.image-card-wed img {
    width: 100%;
    height: 100%;
}

.rodamap .card::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    /* Inherit border-radius from the parent */
    border: 2px solid transparent;
    background: linear-gradient(180deg, #B6FF5A 0%, #DB4DFF 30.01%, #F3BA28 64.01%, #B62361 100%);
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.flex-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 81px;
}

.flex-item h4 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-top: 24px;
}

.ms-desktop {
    margin-left: 48px;
}

.process .header {
    margin-bottom: 50px;
}

.ecosystem-card-main {
    background-image: url('../assets/images/services/WebDesignBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
}

.ecosystem-card-main-embrace {
    background-image: url('../assets/images/services/Embracebg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 591px;
}

.deliver-list {
    padding-top: 181px;
}

.right-sized {
    background-image: url('../assets/images/services/it_bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 490px;
}

.right-sized .erp {
    max-width: 583.19px;
    height: 419.01px;
    top: 570px;
    left: 833px;
    gap: 0px;
}
.deliver-list {
    padding-top: 181px;
}

.deliver-list li {
    max-width: 546px;
    margin-bottom: 17px;
}

.ecosystem-ao h4 {
    color: #FFF;
    text-align: center;
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
}

.ecosystem-ao p {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    margin-top: 8px;
}

.grid_container_4 {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-gap: 25px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.grid_container_flex {
    display: grid;
    grid-template-columns: repeat(3, minmax(363px, 1fr));
    grid-gap: 97px;
    justify-content: space-between;
    margin-bottom: 32px;
}

.grid_container_flex_2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(566px, 1fr));
    grid-gap: 97px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.grid_container_3 {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-gap: 25px;
    justify-content: space-between;
}

.grid_container_2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: 25px;
    justify-content: space-between;
}

.grid-item {
    display: flex;
    align-items: center;
    border: 1px solid #004AAD;
    border-radius: 41px;
    padding: 13px 0px;
    box-shadow: 0px 4px 11px 0px #00000040;
    color: #212529;
    font-size: 24px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    min-height: 82px;
    background-color: #FFFFFF;
}

.erp-automation h2 {
    text-align: center;
    font-weight: 600;
    min-height: 56px;

}

.erp-card h3 {
    background: #2362B6;
    min-height: 51px;
    max-width: 197px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    color: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    margin-bottom: 0px;
}

.list-items {
    max-width: 197px;
    min-height: 267px;
    height: 300px;
    top: 1603px;
    left: 77px;
    gap: 0px;
    margin-top: 50px;
    border-radius: 10px;
    opacity: 0px;
    box-shadow: 0px 0px 9px 2px #00000029;
    background: #FFFFFF;
    opacity: 1;
}

.list-with-lines {
    list-style-type: none;
    padding: 0px 10px;
    margin: 0px;
}

.list-item {
    padding: 16px 0px 8px 0px;
    border-bottom: 0.5px solid #2362B6;
    line-height: 16px;
}

.list-item:last-child {
    border-bottom: none;

}

/* .col-md-8 img{
    position:relative;
    bottom: 150px;
  }
  .col-md-8, .col-md-4{
    margin-top: 50px;
  }
  .col-md-4, .blue-arrow{
    position: relative;
    padding-bottom: 50px;
  } */



section.dm-top {
    /* height: 658px; */
    background-image: url('../assets//images/services/dmtop.webp');
    background-repeat: no-repeat;
    background-size: cover;
    /* background-position: center;     */
}

.dm-card {
    padding: 15px;
}

.dm-card>div {
    width: 439px;
    height: 239px;
    border-radius: 10px;
    border: 1px solid #407BFF;
    padding: 33px;
}

/* li{
    list-style: none;
    font-family: Roboto;
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
text-align: left;

} */

@media only screen and (max-width: 767.98px) {
    section.about-breadcrumb {
        height: 139px;
    }

    .ms-desktop {
        margin-left: 0px;
    }

    .grid-item {
        font-size: 16px;
    }

    .grid_container_4 {
        grid-template-columns: repeat(2, minmax(163px, 1fr));
        gap: 20px;

        .grid-item {
            font-size: 16px;
            font-weight: 600;
        }

        margin-bottom: 20px;
    }

    .list-items {
        margin: 50px auto 0px auto;
    }

    .grid_container_3 {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }

    .process {
        .grid_container_3 {
            grid-template-columns: repeat(2, minmax(163px, 1fr));

            .grid-item {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .grid_container_2 {
        grid-template-columns: repeat(1, minmax(200px, 1fr));
    }

    section.customer-centric {
        text-align: left;
    }

    section {
        padding: 50px 0px;
    }

    section.why-choose img {
        max-width: 100%;
    }

    section.why-choose {
        padding: 50px 12px;
    }

    .ecosystem-card p{
        font-size: 13px;
    }

    .right-sized .order-first{
        order: 1;
    }

    .right-sized img{
        margin: 30px 0px;
    }
}

@media screen and (max-width: 575.98px) {
    section.ao img {
        width: 89%;
    }

    .ecosystem-card-1 {
        margin-left: 25px;
    }

    .ecosystem-ao p {
        color: #FFF;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 28px;
        margin-top: 8px;
    }

    .ecosystem-ao h4 {
        color: #FFF;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: normal;
    }

    .grid_container_flex {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 50px;
        justify-content: space-between;
        margin-bottom: 32px;
    }

    .bggray h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: normal;
    }

    .bggray p {
        font-weight: 400;
        font-size: 13px;
        color: #000;
        line-height: normal;
        margin-top: 8px;
    }

    .grid_container_flex_2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 50px;
        justify-content: space-between;
        margin-bottom: 50px;
    }

    .rodamap .card {
        position: relative;
        min-height: 318px;
        width: 296px;
        background-color: #FFF;
        border-radius: 15px;
        box-shadow: 0px 4px 10px 0px #0000002E;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding-top: 24px;
        padding-left: 23px;
        padding-right: 23px;
    }

    .rodamap h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 56px;
    }

    .Control h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: normal;
        text-align: center;
    }

    .ERP_Adoption .card .card-no {
        left: 80%;
    }

    .ERP_Adoption .card .card-daetail {
        margin-top: 56px;
    }

    .application h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 56px;
        text-align: center;
    }

    .application p {
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
    }

    .application h4 {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
    }

    .application ul li {
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: flex;
        align-items: center;
    }

    .ERP_Adoption h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: normal;
        text-align: center;
        margin-bottom: 50px;
    }

    .flex-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
    }

    section.process p {
        text-align: center;
        font-weight: 400;
        font-size: 13px;
        color: #000;
    }

    .develop p {
        text-align: center;
        font-weight: 400;
        font-size: 13px;
        line-height: 28px;
        color: #000;
        margin-bottom: 50px;
    }

    .develop .card {
        min-height: 398px;
        min-width: auto;
        background-color: #FFF;
        border-radius: 10px;
        box-shadow: 0px 4px 16px 0px #2362B640;
        overflow: hidden;
        display: flex;
        width: auto;
    }

    .dm-card>div {
        width: 100%;
        height: 230px;
        padding: 20px;
    }

    .dm-card h5 {
        font-size: 18px;
    }

    .dm-card p {
        font-size: 13px;
    }
}